<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="assets/angular.svg" alt="" width="30" height="24" class="d-inline-block align-text-top">
      Okta-Angular
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" *ngIf="!(authStateService.authState$ | async)?.isAuthenticated" (click)="login()">Login</a>
        </li>
        <li class="nav-item">
          <a id="profile-button" class="nav-link" *ngIf="(authStateService.authState$ | async)?.isAuthenticated" routerLink="/profile">Profile</a>
        </li>
        <li class="nav-item">
          <a id="logout-button" class="nav-link" *ngIf="(authStateService.authState$ | async)?.isAuthenticated" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="ui text container" style="margin-top: 7em;">
  <router-outlet></router-outlet>
</div>
