
export default {
  oidc: {
    clientId: `0oa7yvhdcj8UUaMWO357`,
    issuer: `https://sso.okta.uno/oauth2/default`,
    redirectUri: '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `false`
    }
  },
};
