<div *ngIf="error" class="error">
  {{ error }}
</div>

<h1 class="ui header">PKCE Flow Okta Hosted Login Page</h1>
<div *ngIf="!isAuthenticated">
  <p>If you‘re viewing this page then you have successfully started this Angular application.</p>
  <p>This example shows you how to use the
    <a href="https://github.com/okta/okta-angular/tree/master">Okta Angular Library</a> to add the
    <a href="https://developer.okta.com/docs/guides/implement-auth-code-pkce">PKCE Flow</a> to your application.</p>
  <p>When you click the login button below, you will be redirected to the login page on your Okta org. After you authenticate,
    you will be returned to this application with an ID Token and Access Token. These tokens will be stored in local storage
    and can be retrieved at a later time.</p>
  <button id="login-button" type="button" class="btn btn-primary" role="button" (click)="login()">Login</button>
</div>

<div *ngIf="isAuthenticated && userName">
  <p>Welcome back, {{ userName }}!</p>
  <p>
    You have successfully authenticated against your Okta org, and have been redirected back to this application.  You now have an ID Token and access token in local storage.
    Visit the <a href="/profile">My Profile</a> page to take a look inside the ID Token.
  </p>
</div>
